<template>
  <div>
      <!--去评价-->
      <el-dialog v-if="!serviceScore && !comment" :close-on-click-modal="false" :visible="evaluateShow" center width="400px" @close="close">
        <el-row>
          <el-col :span="8">评价</el-col>
          <el-col :span="16">
            <el-rate v-if="serviceScore" disabled :value="serviceScore" />
            <el-rate v-else v-model="form.serviceScore" />
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">评价内容</el-col>
          <el-col :span="16">
            <el-input v-if="comment" disabled :value="comment" type="textarea" />
            <el-input v-else v-model="form.comment" type="textarea" />
          </el-col>
        </el-row>
        <div slot="footer">
          <el-button @click="closeEvaluate">取消</el-button>
          <el-button type="primary" @click="confirm">确定</el-button>
        </div>
      </el-dialog>

      <!--评价展示-->
      <el-dialog v-else :close-on-click-modal="false" :visible="evaluateShow" center width="400px" @close="close">
      <el-row>
        <el-col :span="8">评价</el-col>
        <el-col :span="16">
          <el-rate disabled :value="serviceScore" />
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">评价内容</el-col>
        <el-col :span="16">
          <el-input disabled :value="comment" type="textarea" />
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { goEvaluate } from '@/api/profilePanel';
import { mapState, mapMutations } from 'vuex';
import { Message } from 'element-ui';

export default {
  name: "evaluation",
  data() {
    return {
      show: false,
      form: {
        serviceScore: 0,
        comment: '',
        three: ''
      },
    }
  },
  created() {
    console.log('xxx', this.serviceScore)
  },
  computed: {
    ...mapState(['evaluateShow', 'id', 'contentId', 'serviceScore', 'comment', 'three'])
  },
  methods: {
    ...mapMutations(['closeEvaluate']),
    close() {
      this.closeEvaluate();
      // this.form = {
      //   "assessTime": 5,  //题目易懂性（0，1，2，3，4，5）
      //   "useTime": 5, //结果准确性（0，1，2，3，4，5）
      //   "assessAddr": 5, //建议实用性（0，1，2，3，4，5）
      //   "shareContent": "", //测评心得分享
      //   "isAnonymous": 1 //是否匿名评价 1是 0否
      // }
      // this.$emit('close')
    },

    // 提交评价
    confirm() {
      this.form.contentId = this.contentId;
      this.form.id = this.id;
      this.form.three = this.three;
      goEvaluate(this.form).then(res => {
        // 关闭弹窗
        this.closeEvaluate();

        // 刷新列表
        this.$emit('success');
        Message({
          type: 'success',
          message: '操作成功'
        })
      })
    },
    handleShow(scaleCode, cusCode) {
      this.form.customerCode = cusCode
      this.form.scaleCode = scaleCode
      this.show = true
    },
    open() {
      this.show = true;
    },

    // 查看评价
  }
}
</script>

<style lang="scss" scoped>
.el-row {
  margin-bottom: 15px;
}
</style>
