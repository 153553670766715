<template>
  <div class="panel-wrap">
    <!--&lt;!&ndash;头部&ndash;&gt;-->
    <!--<header class="panel-head">-->
    <!--  <el-select v-model="value" placeholder="请选择">-->
    <!--    <el-option-->
    <!--        v-for="item in options"-->
    <!--        :key="item.value"-->
    <!--        :label="item.label"-->
    <!--        :value="item.value">-->
    <!--    </el-option>-->
    <!--  </el-select>-->
    <!--</header>-->

    <template v-if="counselList && counselList.length">
      <!--评论咨询师列表-->
      <div class="comment-list-box no-scrollbar">
        <div class="comment-list">
          <CommentCounselor
            v-for="item in counselList"
            :key="item.id"
            ref="CommentCounselor"
            :handel="evaluatePopup"
            :item="item"/>
        </div>
      </div>

      <!--分页-->
      <!--<el-row justify="end" style="margin-top: 15px;" type="flex">-->
      <!--  <el-col :span="5">-->
      <!--    <el-pagination-->
      <!--        :total="total"-->
      <!--        background-->
      <!--        layout="prev, pager, next"-->
      <!--        @current-change="currentPageHandle">-->
      <!--    </el-pagination>-->
      <!--  </el-col>-->
      <!--</el-row>-->
    </template>

    <!--数据为空时-->
    <el-empty v-else description="暂无数据" style="height: 100%" />


    <!--评价弹窗-->
    <EvaluatePopup ref="evaluate" @success="commentSuccess"/>
  </div>
</template>

<script>
import CommentCounselor from "../components/CommentCounselor";
import EvaluatePopup from "../components/EvaluatePopup";
import { getPanelInfo } from '../api/profilePanel';
import dayjs from "dayjs";

export default {
  components: {
    CommentCounselor,
    EvaluatePopup
  },
  data() {
    return {
      options: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }, {
        value: '选项5',
        label: '北京烤鸭'
      }],
      value: '',
      counselList: [],
      currPage: 1, // 当前页
      totalPage: 0, // 总页数 初始值为 1
      total: 0, // 总数
    }
  },
  mounted() {
    this.acquirePanelInfo(this.currPage);
  },
  methods: {
    commentSuccess() {
      this.refreshList(this.currPage)
      // this.
    },
    // 获取面板数据
    acquirePanelInfo(currPage) {

      getPanelInfo({
        currPage,
        pageSize: 9
      }).then(({ data }) => {
        console.log('获取的数据：', data)

        this.counselList = data;
        // 总条数
        // this.total = data.totalCount;
      });
    },

    // 分页事件
    currentPageHandle(val) {
      this.currPage = val

      this.acquirePanelInfo(val)
    },

    // 评价弹窗
    evaluatePopup() {
      this.$refs.evaluate.open()
    },

    // 刷新列表
    refreshList(currPage) {
      getPanelInfo({
        currPage,
        pageSize: 9
      }).then(({ data }) => {
        this.counselList = data;
        this.$forceUpdate();
      })
    },

    // 过滤时间
    formatDate(time, format) {
      return dayjs(time).format(format)
    },
  }
}
</script>

<style lang="scss" scoped>
.panel-head {
  padding-bottom: 8px;
  border-bottom: 1px solid #F1F1F1;
}

.comment-list-box {
  overflow-y: auto;
  height: calc(100% - 51px);
}

.comment-list {
  width: 100%;
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-row-gap: 8px;
  grid-column-gap: 8px;
  padding: 5px;
}

.panel-wrap {
  background: white;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
  height: 100%;

}
</style>
