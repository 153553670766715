<template>
  <div class="comment-counselor-wrap">
    <img :src="item.teacherAvatar" alt="0" class="counselor-img"/>
    <div class="counselor-right">
      <span class="counselor-name">{{ item.consultantName }}</span>
      <span class="counselor-label">{{ item.serviceName }}</span>
      <el-row style="font-size: 12px; margin-top: 5px">咨询时间：{{
          formatDate(item.consultTime, 'YYYY-MM-DD HH:mm:ss')
        }}
      </el-row>
      <el-row style="font-size: 12px">咨询时长：{{ item.serviceDuration }}分钟</el-row>
      <div slot="reference" class="counselor-desc " v-html="item.serviceOutline">
      </div>
      <div class="footer">
        <el-button v-if="item.status === 1" plain round size="small" type="primary">待咨询</el-button>
        <el-button v-if="item.status === 3 && item.comment" plain round size="small" type="primary" @click="goEvaluate">
          查看评价
        </el-button>
        <el-button v-if="item.status === 3 && !item.comment" plain round size="small" type="primary"
                   @click="goEvaluate">
          立即评价
        </el-button>
        <el-button v-if="item.status === 3" round size="small" type="success">已完成</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations} from 'vuex';
import dayjs from "dayjs";

export default {
  props: {
    evaluatePopup: {
      type: Function,
      required: false,
      default: function () {

      }
    },
    item: {
      type: Object,
      required: true,
    }

  },
  methods: {
    ...mapMutations(['openEvaluate']),
    // 去评价 查看评价
    goEvaluate() {
      this.openEvaluate(this.item)
    },

    // 过滤时间
    formatDate(time, format) {
      return dayjs(time).format(format)
    },
  },
  watch: {
    'item': {
      handler(e) {
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.comment-counselor-wrap {
  box-sizing: border-box;
  padding: 22px 22px 17px 10px;
  width: 100%;
  height: 236px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  display: flex;
}

.counselor-img {
  width: 171px;
  height: 192px;
  border-radius: 6px;
  background: pink;
  margin-right: 17px;
}

.counselor-name {
  font-size: 20px;
  font-weight: bold;
  color: #000000;
}

.counselor-label {
  margin-left: 19px;
  padding: 4px 19px 5px 21px;
  background: rgba(78, 111, 218, 0.19);
  border-radius: 100px;
  font-size: 16px;
  color: #4E6FDA;
}

.counselor-right {
  padding-top: 6px;
}

.counselor-desc {
  margin-top: 5px;
  width: 306px;
  height: 75px;
  font-size: 12px;
  line-height: 25px;
  color: #5D5D5D;
  overflow: hidden;
}

.finished-btn {
  display: inline-block;
  width: 120px;
  height: 35px;
  background: #1FA83F;
  border-radius: 100px;
  text-align: center;
  line-height: 35px;
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
}

.comment-btn {
  margin-left: 19px;
  display: inline-block;
  width: 120px;
  height: 35px;
  border: 1px solid #4F77F9;
  border-radius: 100px;
  font-size: 14px;
  color: #4F77F9;
  text-align: center;
  line-height: 35px;
  cursor: pointer;
}

.footer {
  display: flex;
  justify-content: flex-end;
}
</style>
